.grid1, .grid2, .grid1-2, .grid2-1, .grid1-3, .grid3-1, .grid3, .grid3-footer, .grid5, .grid1-10, .grid2-10, .grid4, .grid6 {
    display: grid;
    grid-gap: 15px;
    row-gap: 15px;
    align-items: start;
    justify-items: stretch;
}
.grid1 {
    grid-template-columns: 1fr;
    
}
.grid1-2{
    grid-template-columns: 1fr 2fr;
}
.grid2-1{
    grid-template-columns: 2fr 1fr;
}
.grid1-3{
    grid-template-columns: 1fr 3fr;
}
.grid1-4{
    grid-template-columns: 1fr 4fr;
}
.grid3-1{
    grid-template-columns: 3fr 1fr;
}
.grid3-footer{
    grid-template-columns: repeat(3, 1fr);
}
.grid3{
    grid-template-columns: repeat(3,1fr);
}
.grid4{
    grid-template-columns: repeat(4,1fr);
}
.grid5 {
    grid-template-columns: repeat(5, 1fr);
}
.grid1-10 {
    margin-top: 50px;
    grid-template-columns: 1fr 10fr;
}
.grid2{
    grid-template-columns: 1fr 1fr;
    padding: 0px 5px;
    gap: 30px;
}
.grid2-10 {
    grid-template-columns: 2fr 10fr;
}


.wrap{
    flex-wrap: wrap;
}
.hidden{
    display: none !important;
}
.floatRight{
    float: right;
}
.floatLeft{
    float: left;
}
.centerText{
    text-align: center;
}
.w100{
    width: 100%;
}
.p25{
    padding: 25px;
}
.appElementContent{
    padding:25px
}
.bottom30{
    margin-bottom: 30px;
}
.top30{
    margin-top: 30px;
}
.left30{
    margin-left: 30px;
}
.padding20 {
    padding: 20px;
}

.selfCenter{
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.padding10 {
    padding: 10px;
}



.top35 {
    margin-top: 35px;
  }
  
  .maxH600{
      max-height: 600px;
  }
  .appElement {
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    background-color: var(--backgroundElement);
    height: fit-content;
    width: 100%;
    overflow: auto;
    margin-bottom: 15px;
  }
  .appElement > table {
    padding: 25px;
  }
  .appElementSimple {
    height: fit-content;
    width: 100%;
    margin-top: -2px;
  }
  
  .appElementHeader {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 20.8px 30px;
    width: calc(100% - calc(30px * 2));
    border-bottom: 1px solid var(--backgroundColor);
    border-radius: 4px 4px 0 0;
    color: var(--fontColorForm);
  }


  
@media (max-width: 500px) {
    .grid2,.grid1-2,.grid2-1,.grid1-3,.grid3, .grid5,.grid4{
        grid-template-columns: repeat(1, 1fr);
    }

}
@media (max-width: 768px) {
    .grid2-10 {
        grid-template-columns: 1fr;
    }
}
@media (max-width: 1260px) {

}
@media (min-width:426px)  and (max-width:767px) {

}

@media (min-width: 769px) and (max-width: 1368px) {

}

@media (min-width: 1368px) and (max-width: 1440px){
    .grid4{
        grid-template-columns: repeat(3,1fr) !important;
    
        }
}

/* Tablet */
@media(min-width: 750px) and (max-width: 1200px){

   .grid2{
       justify-items: center;
   }
   .grid3-footer{
       grid-template-columns: repeat(2,1fr);
   }
}