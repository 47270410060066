button {
  font-size: 1.1em;

  padding: 10px 15px;
  margin: 10px 3px;
  border-radius: 30px;

  display: inline-block;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  transition: opacity var(--transition);
}
button:focus,
button:active:focus {
  outline: none;
}

button:hover {
  opacity: 0.8;
}

.btnLg {
  padding: 18px 24px !important;
}

.btnRounded {
  border-radius: 10px;
}

.btnSm {
  display: inline-block;
  width: fit-content;
  cursor: pointer;
  padding: 5px 7px;
  margin: 5px 3px;
  border-radius: 30px;
}

.btnTransparent {
  display: inline-block;
  background-color: transparent;
  color: var(--colorPrincipal);
  border: 1px solid var(--colorPrincipal);
  width: fit-content;
  cursor: pointer;
  padding: 10px 15px;
  margin: 10px 3px;
  border-radius: 30px;
}

.btnTransparent:hover {
  color: var(--backgroundElement);
  background-color: var(--colorPrincipal);
}

.btnAlert {
  padding: 2px 8px;
  font-size: 0.8rem;
  right: 5px;
  top: 5px;
  position: absolute;
  margin: 0px;
  background: #00000026;
  color: inherit;
}


.outlineInfo{
  color:var(--info);
  border-color: var(--info);
  background-color: transparent;
}
.outlineSecundary{
  background-color: transparent;
  border-color: var(--backgroundColor);
  color: var(--backgroundColor);
}
@media (max-width: 500px) {
  button {
    padding: 5px 7px;
  }

  #iniciarSesionBtn {
    font-size: 16px !important;
  }
}
