table{
    width: 100%;
}
tr:nth-child(even){
    background-color: var(--backgroundColor);
}
thead{
    border-bottom: 1px solid var(--backgroundColor);
}
td{
    border: 0px;
    padding: 20px;
}
tr{
    cursor: pointer;
}
th{
    padding: 20px;
}

td button{
    margin-left: 3px !important;
}

.onlyPrint{
    display: none;
}

.onlyPrint.onlyPrintShow{
    display: table-cell;
}

/* Responsive */
/* Móvil */
@media(max-width: 500px){
    .appElement > table{
        padding: 10px;
        display: inline-table;
    }
    table{
        display: block;
        overflow-x: scroll;
    }
    tr{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .appElement > table tr{
        justify-content: space-evenly;
    }
    th,td{
        padding: 2%;
    }
    .inputWidget{
        height: 22px !important;
        padding: 2%;
    }
}