

.topBar {
    position: fixed;
    width: 100%;
    padding: 10px;
    top: 0px;
    background-color: var(--colorPrincipal);
    right: 0px;
    color: #fff;
    display: grid;
    grid-template-columns: 3fr 1fr;
    z-index: 1;
  }
  .topBar span {
    cursor: pointer;
  }
  .topBar span:hover {
    opacity: 0.8;
  }
  .topNavUser {
    text-align: right;
  }
  .brand {
    text-align: center;
    font-weight: bold;
    overflow: hidden;
  }
  
  .brand img {
    height: 54px;
  }


.topBarNav{
    display: flex;
    background: #4d9bd5;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    left: 0px;
    top: 78px;
    box-shadow: var(--boxShadow);
}

.topBarNavElement{
    color:#fff;
    padding: 15px;
    transition: all var(--transition);

}

.topBarNavElement:hover,
.topNavActive > .topBarNavElement{
    background-color: var(--colorPrincipal);
}