@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;600&display=swap");

@import "colors.css";
@import "modal.css";
@import "cargador.css";
@import "buttons.css";
@import "form.css";
@import "alert.css";
@import "grid.css";

:root {
  --borderRadius: 10px;
  --transition: ease 0.6s;

  --boxShadow: 0px 0px 7px 0px #d9d9d9;
}
*,
*:before,
*:after {
  box-sizing: content-box;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--colorPrincipal);
  border-radius: var(--borderRadius);
}
h1,
h2,
h3,
h4,
h5 {
  font-family: Raleway;
  font-weight: 400;
  margin: 0px;
}
body {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100%;
  font-weight: 400;
  text-align: left;
  text-transform: none;
  background-color: var(--backgroundColor);
  margin: 0;
  overflow-x: hidden;
  font-style: normal;
  color: var(--fontColor);
  overflow-wrap: break-word;
}

a {
  color: var(--colorPrincipal);
  text-decoration: none;
}
p {
  line-height: 25px;
}
ul {
  list-style: none;
  padding: 0px;
}

.headerSection {
  font-size: 2.2rem;
  margin: 10px;
}

.loginContainer {
  top: 10%;
  position: absolute;
  width: 50%;
  left: 25%;
}

.appElement {
  background-color: var(--backgroundElement);
}

.actividadSelect {
  padding: 5px;
  cursor: pointer;
}
.actividadSelect:hover {
  background-color: var(--colorPrincipal);
  color: #fff;
}

.center {
  text-align: center;
}

.quizzSelector {
  padding: 10px;
  border-radius: var(--borderRadius);
  color: #fff;
  background-color: var(--colorPrincipal);
  margin: 10px;
  box-shadow: var(--boxShadow);
  cursor: pointer;
  font-weight: bolder;
  text-align: center;
  font-size: 1.5rem;
  transition: all 0.8s;
}

.quizzSelector:hover {
  transform: scale(1.2);
}

.rbq_answer_selected,
.rbq_question_overlap_text {
  background-color: var(--colorPrincipal) !important;
}

.rbq_question_overlap_text {
  z-index: -1 !important;
}
.rbq_individual_answer_outer_container,
.rbq_question_overlap_text {
  border-radius: var(--borderRadius);
}
.rbq_question_inner_container {
  background-color: transparent !important;
}

.rbq_result_footer {
  display: none !important;
}

.rbq_result_outer_container {
  border-radius: var(--borderRadius);
  box-shadow: 0px 0px 20px 0px #323232;
  background-image: linear-gradient(
    180deg,
    #638f66,
    #6caf70 51.44%,
    #6aaa6e
  ) !important;
}




.wpButton {
  position: fixed;
  right: 30px;
  bottom: 35px;
  font-size: 32px;
  border-radius: 30px;
  background: #53bd6a;
  color: #fff;
  width: 48px;
  height: 48px;
  padding: 5px !important;
  line-height: 1;
  box-shadow: var(--boxShadow);
}
.wpButton svg{
fill:#fff !important
}

.topBarMenu,
.topNavUser {
  display: flex;
  justify-content: center;
  align-content: flex-end;
  align-items: center;
}
.homeListaItinerariosContainer {
  margin-top: 70px;
}

.listadoItinerarioHome {
  padding: 15px;
  border-radius: var(--borderRadius);
  transition: all var(--transition);
  margin: 5%;
  box-shadow: var(--boxShadow);
  text-align: center;
  min-height: 198px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;

  color: #fff;
}

.listadoItinerarioHomeTitulo {
  margin-bottom: 35px;
}

.listadoItinerarioHome:hover {
  transform: scale(1.1) rotate(-3deg);
}

.listadoItinerarioHome:nth-child(2n + 1) {
  background-color: var(--success);
}

.listadoItinerarioHome:nth-child(2n + 2) {
  background-color: var(--info);
}





/* botones del editor html */



/* .rdw-block-wrapper,
.rdw-fontfamily-dropdown,
.rdw-fontsize-wrapper,
div[title="Color Picker"],
div[title="Center"],
div[title="Embedded"],
div[title="Emoji"],
div[title="Image"],
div[title="Indent"],
div[title="Justify"],
div[title="Left"],
div[title="Monospace"],
div[title="Ordered"],
div[title="Outdent"],
div[title="Remove"],
div[title="Right"],
div[title="Strikethrough"],
div[title="Subscript"],
div[title="Superscript"],
div[title="Link"]  {
  display: none !important;
}
.editorPaginaContainer .rdw-block-wrapper,
.editorPaginaContainer .rdw-fontfamily-dropdown,
.editorPaginaContainer .rdw-fontsize-wrapper,
.editorPaginaContainer div[title="Color Picker"],
.editorPaginaContainer div[title="Center"],
.editorPaginaContainer div[title="Embedded"],
.editorPaginaContainer div[title="Emoji"],
.editorPaginaContainer div[title="Image"],
.editorPaginaContainer div[title="Indent"],
.editorPaginaContainer div[title="Justify"],
.editorPaginaContainer div[title="Left"],
.editorPaginaContainer div[title="Monospace"],
.editorPaginaContainer div[title="Ordered"],
.editorPaginaContainer div[title="Outdent"],
.editorPaginaContainer div[title="Remove"],
.editorPaginaContainer div[title="Right"],
.editorPaginaContainer div[title="Strikethrough"],
.editorPaginaContainer div[title="Subscript"],
.editorPaginaContainer div[title="Superscript"],
.editorPaginaContainer div[title="Underline"],
.editorPaginaContainer div[title="Unordered"] {
  display: inline-block !important;
}
 */
@media (max-width: 500px) {
  .loginContainer {
    width: 95%;
    left: 2.5%;
  }
}
