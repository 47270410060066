
.roundSpinner {
    border: 10px solid rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-left-color: var(--colorPrincipal);
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }