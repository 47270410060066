#cargador{
    display: block;
    position: fixed;
    top:0;
    width: 100%;
    height: 5px;
    border-radius: var(--borderRadius);
    background-color: transparent;
    z-index: 999999;
}
#spinner{
    animation-name: spinner;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    background-color: var(--colorPrincipal);
    height: 5px;
    position: absolute;
    display: none;

}


@keyframes spinner{
0%{
        left:0%;
        width: 10%;
    }
    50%{
        left:50%;
        width: 50%;
    }

    100%{
        left:100%;
        width: 10%;
    }

}

