.alert {
    top: 10px;
    position: fixed;
    width: 50%;
    left: 25%;
    border-radius: var(--borderRadius);
    padding: 10px;
    box-shadow: var(--boxShadow);
    z-index: 9;
    transition: all ease-in 1.3s;
  }