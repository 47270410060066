@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;600&display=swap);
:root{
    --success: #007a33;
    --danger:#da4141;
    --info:rgb(6 0 122);
    --warning:#ffc601;
    --heart:#f3103c;


    --colorPrincipal:#29628f;
    --backgroundColor:#f7f7f7;
    --backgroundElement:#fff;

    --fontColor:rgb(31, 31, 31);
    --fontColorForm: rgb(41, 41, 41);
}


.success{
    background-color: #007a33;
    background-color: var(--success);
    color: #fff;
}

.success svg{
    color:#fff !important;
    fill:#fff !important;
}

.success path{
    stroke:#fff !important;

}

.outlineSuccess{
    background-color: transparent;
    color: #007a33;
    color: var(--success);
    border:1px solid #007a33;
    border:1px solid var(--success)
}


.outlineSuccess svg{
    color:#007a33 !important;
    color:var(--success) !important;
    fill:#007a33 !important;
    fill:var(--success) !important;
}

.outlineSuccess path{
    stroke:#007a33 !important;
    stroke:var(--success) !important;

}


.primary{
    background-color: #29628f;
    background-color: var(--colorPrincipal);
    color: #fff;
}
.outlineDanger{
    background-color: transparent;
    color: #da4141;
    color: var(--danger);
    border:1px solid #da4141;
    border:1px solid var(--danger)
}

.outlineWarning{
    background-color: transparent;
    color: #ffc601;
    color: var(--warning);
    border:1px solid #ffc601;
    border:1px solid var(--warning)
}


.danger{
    background-color: #da4141;
    background-color: var(--danger);
    color: #fff;

}

.info{
    background-color: rgb(6 0 122);
    background-color: var(--info);
    color: #fff;
}

.warning{
    background-color: #ffc601;
    background-color: var(--warning);
    color:#fff
}

.colorFacebook{
    color:#135cbb !important
}
.colorTwitter{
color:#1da1f2 !important
}
.colorWhatsapp{
color:#1bd741 !important
}
#modal-container {
    position: absolute;
    top: 10px;
    left: 0;
    overflow: 100;
    text-align: left;
    width: 100%;
    min-height: 100px;
    max-height: -webkit-max-content;
    max-height: max-content;
    display: none;
  
  }
  #modal{
    opacity: 0;
    display: none;
    margin: 0 auto;
    border-radius: 5px;
    margin-top: 100px;
    z-index: 9;
    position:static;
    position:initial;
    min-width: 40%;
    max-width: 95%;
    max-height: 90%;
    background-color: var(--backgroundElement);
    overflow: hidden auto;
    transition:opacity var(--transition)
  }
  
  #modal p {
    font-size: 16px;
    margin: 0 0 17px;
  }
  
  .showModal{
    display: block !important;
    opacity:1 !important;
    animation-name: showModal;
    animation-duration: var(--transition);
    position: relative;
    top:50%;
  }
  #bgModal{
    z-index: 8;
    position: fixed;
    width:100%;
    height: 100%;
    background-color: black;
    display: none;
    opacity: 0;
    transition: opacity var(--transition);
    top: 0;
  }
  .headerModal{
    padding: 35px;
    background-color: var(--backgroundColor);
    font-size: 24px;
    color: var(--colorTitle);
  }
  
  #contentModal{
    padding: 20px;
  }
  #contentModal .grid1,
  #contentModal .grid2,
  #contentModal .grid3,
  #contentModal .grid4{
    grid-gap: 5px;
  }

  #closeModal{
    margin: 20px;
    float: right;
    background-color: var(--backgroundMenu);
    color: var(--fontColor);
    transition: var(--transition);
    font-size: 25px;
    padding: 5px 10px 2px;
    border-radius: 100%;
  }
  
  
  #closeModal:hover{
    background-color: var(--colorPrincipal);
    color:var(--colorPrincipalHover);
  }
  /* Tablet */
  @media(min-width: 750px) and (max-width: 1200px){
      #modal{
          min-width: 76%;
      }
  }
#cargador{
    display: block;
    position: fixed;
    top:0;
    width: 100%;
    height: 5px;
    border-radius: var(--borderRadius);
    background-color: transparent;
    z-index: 999999;
}
#spinner{
    animation-name: spinner;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    background-color: var(--colorPrincipal);
    height: 5px;
    position: absolute;
    display: none;

}


@keyframes spinner{
0%{
        left:0%;
        width: 10%;
    }
    50%{
        left:50%;
        width: 50%;
    }

    100%{
        left:100%;
        width: 10%;
    }

}


button {
  font-size: 1.1em;

  padding: 10px 15px;
  margin: 10px 3px;
  border-radius: 30px;

  display: inline-block;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  transition: opacity var(--transition);
}
button:focus,
button:active:focus {
  outline: none;
}

button:hover {
  opacity: 0.8;
}

.btnLg {
  padding: 18px 24px !important;
}

.btnRounded {
  border-radius: 10px;
}

.btnSm {
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  padding: 5px 7px;
  margin: 5px 3px;
  border-radius: 30px;
}

.btnTransparent {
  display: inline-block;
  background-color: transparent;
  color: var(--colorPrincipal);
  border: 1px solid var(--colorPrincipal);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  padding: 10px 15px;
  margin: 10px 3px;
  border-radius: 30px;
}

.btnTransparent:hover {
  color: var(--backgroundElement);
  background-color: var(--colorPrincipal);
}

.btnAlert {
  padding: 2px 8px;
  font-size: 0.8rem;
  right: 5px;
  top: 5px;
  position: absolute;
  margin: 0px;
  background: #00000026;
  color: inherit;
}


.outlineInfo{
  color:var(--info);
  border-color: var(--info);
  background-color: transparent;
}
.outlineSecundary{
  background-color: transparent;
  border-color: var(--backgroundColor);
  color: var(--backgroundColor);
}
@media (max-width: 500px) {
  button {
    padding: 5px 7px;
  }

  #iniciarSesionBtn {
    font-size: 16px !important;
  }
}

input,
textarea,
select,
.wrap > div {
  font-size: 1.1rem;
  width: 100%;
  font-family: inherit;
}
input[type="checkbox"] {
  width: auto;
  margin: 10px;
  transform: scale(1.5);
}

.inputWithIcon {
  display: flex;
  align-content: center;
  width: 100%;
  font-size: 1.2em;
  border: 1px solid var(--backgroundColor);
  margin-top: 10px;
}
.inputWithIcon i {
  color: #a3a2a2;
  font-size: 0.8em;
}
.inputWithIcon > input,
.inputWithIcon > select {
  border: none !important;
  box-shadow: none !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding: 8px;
  font-size: 0.9em;
  
}
.inputWithIcon input:-ms-input-placeholder {
  color: #bebbbb !important;
}
.inputWithIcon input::placeholder {
  color: #bebbbb !important;
}
.inputWithIcon > label {
  padding: 8px 0px;
  padding-left: 10px;
  margin-right: 5px;
  margin-top: -4px;
}
.inputSection label {
  color: var(--colorLetterForms);
  font-weight: bolder;
  font-size: 1.1em;
}

.resultadoHead label{
  margin-bottom: 20px;

}
.no {
  padding: 0;
  margin: 0;
}

.appElement textarea {
  padding: 10px;
  margin-left: 25px;
  height: 100%;
  width: 50%;
  max-width: 70%;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.inputSection {
  padding: 5px;
  display: flex;
  margin: 10px;
  width: 94%;
}
.inputSection.politica {
  margin-top: 22px;
}
.inputSection.politica input[type="checkbox"] {
  margin-top: 4px;
  margin-right: 10px;
}
.conditions {
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

input[type="range"] {
  -webkit-appearance: none;
  background-color: var(--colorPrincipal);
  padding: 0px;
  height: 5px;
  border-radius: 5px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid var(--colorPrincipal);
  background-color: var(--backgroundColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
}
.appElement button {
  margin: 15px;
}

.uploadAvatar {
  background-color: var(--headerBackGroundForm);
  border: 2px dashed var(--colorBorderAvatar);
  width: 25%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 39px;
  border-radius: 2px;
}

.uploadAvatar p {
  padding: 20px 34.8771px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
}

label {
  color: var(--colorLetterForms);
  font-weight: 600;
  font-size: 15px;
  padding-top: 1px;
}

input,
textarea,
select {
  color: var(--fontColorForm);
  font-weight: 400;
  border: 2px solid var(--backgroundColor);
  margin-bottom: 15px;
  margin-top: 9px;
  box-shadow: 0px 0px 2px 0px #cdcdcd;
  transition: all var(--transition);
  padding: 10px 12px;
  border-radius: var(--borderRadius);
}
input:focus,
textarea:focus,
select:focus{
  box-shadow: 0px 0px 2px 0px var(--colorPrincipal);

}

.conditions {
  background-color: var(--backGroundColorConditions);
  color: var(--colorConditions);
  padding: 22px 0px;
  padding-left: 25px;
  padding-right: 50px;
  margin: 5px 5px 20px 5px;
  font-weight: 450;
  font-size: 15px;
}

.form-container {
  padding: 15px;
}

.form-container label {
  display: flex;
}
.form-container label {
  margin-top: 20px;
}
.input-15pd {
  width: calc(100% - 25px * 2) !important;
}

.switch {
  position: relative;
  display: block;
  width: 60px;
  height: 13px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.input-switch.style-switch-one input[type="checkbox"]:checked + label:before {
  background-color: var(--colorPrincipal) !important;
  height: 30px !important;
  width: 50px !important;
}

.input-switch.style-switch-one input[type="checkbox"] + label:after {
  width: 30px !important;
  height: 30px !important;
}

.input-switch.style-switch-one input[type="checkbox"] + label {
  background-color: #c5c3c3;
  width: 50px !important;
  height: 30px !important;
}
.input-switch.style-switch-one input[type="checkbox"]:checked + label {
  background-color: var(--colorPrincipal) !important;
}

.wrongInput {
  border-color: var(--danger) !important;
  box-shadow: 0px 0px 3px 1px var(--danger) !important;

}

.editorQwerty {
  height: 275px !important;
  height: 275px !important;
  border: 2px solid var(--backgroundColor);
  padding: 15px;
}

select[multiple] option:checked {
  background: var(--colorPrincipal)
    linear-gradient(0deg, var(--colorPrincipal) 0%, var(--colorPrincipal) 100%);
}

/* widgetForm */

.inputWidget {
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 10%);
  font-size: 16px;
  font-weight: 600;
  height: 35px !important;
  padding: 10px 16px;
  line-height: 30px;
  margin: 0 0 15px 0;
  position: relative;
  background-color: #fff;
  text-align: left;
  color: var(--fontColorForm);
  transition: color 0.3s !important;
}

/* Móvil */
@media (max-width: 500px) {
  .inputSection {
    width: 89%;
  }
}

/* select multiple */
.css-g1d714-ValueContainer {
  max-height: 43px;
  overflow: auto !important;
}
.css-1pahdxg-control:hover {
  border-color: var(--colorPrincipal) !important;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: var(--colorPrincipal) !important;
}

.css-xb97g8:hover {
  background-color: var(--colorPrincipal) !important;
  color: #fff !important;
}

.alert {
    top: 10px;
    position: fixed;
    width: 50%;
    left: 25%;
    border-radius: var(--borderRadius);
    padding: 10px;
    box-shadow: var(--boxShadow);
    z-index: 9;
    transition: all ease-in 1.3s;
  }
.grid1, .grid2, .grid1-2, .grid2-1, .grid1-3, .grid3-1, .grid3, .grid3-footer, .grid5, .grid1-10, .grid2-10, .grid4, .grid6 {
    display: grid;
    grid-gap: 15px;
    grid-row-gap: 15px;
    row-gap: 15px;
    align-items: start;
    justify-items: stretch;
}
.grid1 {
    grid-template-columns: 1fr;
    
}
.grid1-2{
    grid-template-columns: 1fr 2fr;
}
.grid2-1{
    grid-template-columns: 2fr 1fr;
}
.grid1-3{
    grid-template-columns: 1fr 3fr;
}
.grid1-4{
    grid-template-columns: 1fr 4fr;
}
.grid3-1{
    grid-template-columns: 3fr 1fr;
}
.grid3-footer{
    grid-template-columns: repeat(3, 1fr);
}
.grid3{
    grid-template-columns: repeat(3,1fr);
}
.grid4{
    grid-template-columns: repeat(4,1fr);
}
.grid5 {
    grid-template-columns: repeat(5, 1fr);
}
.grid1-10 {
    margin-top: 50px;
    grid-template-columns: 1fr 10fr;
}
.grid2{
    grid-template-columns: 1fr 1fr;
    padding: 0px 5px;
    grid-gap: 30px;
    gap: 30px;
}
.grid2-10 {
    grid-template-columns: 2fr 10fr;
}


.wrap{
    flex-wrap: wrap;
}
.hidden{
    display: none !important;
}
.floatRight{
    float: right;
}
.floatLeft{
    float: left;
}
.centerText{
    text-align: center;
}
.w100{
    width: 100%;
}
.p25{
    padding: 25px;
}
.appElementContent{
    padding:25px
}
.bottom30{
    margin-bottom: 30px;
}
.top30{
    margin-top: 30px;
}
.left30{
    margin-left: 30px;
}
.padding20 {
    padding: 20px;
}

.selfCenter{
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.padding10 {
    padding: 10px;
}



.top35 {
    margin-top: 35px;
  }
  
  .maxH600{
      max-height: 600px;
  }
  .appElement {
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    background-color: var(--backgroundElement);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    overflow: auto;
    margin-bottom: 15px;
  }
  .appElement > table {
    padding: 25px;
  }
  .appElementSimple {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: -2px;
  }
  
  .appElementHeader {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 20.8px 30px;
    width: calc(100% - calc(30px * 2));
    border-bottom: 1px solid var(--backgroundColor);
    border-radius: 4px 4px 0 0;
    color: var(--fontColorForm);
  }


  
@media (max-width: 500px) {
    .grid2,.grid1-2,.grid2-1,.grid1-3,.grid3, .grid5,.grid4{
        grid-template-columns: repeat(1, 1fr);
    }

}
@media (max-width: 768px) {
    .grid2-10 {
        grid-template-columns: 1fr;
    }
}
@media (max-width: 1260px) {

}
@media (min-width:426px)  and (max-width:767px) {

}

@media (min-width: 769px) and (max-width: 1368px) {

}

@media (min-width: 1368px) and (max-width: 1440px){
    .grid4{
        grid-template-columns: repeat(3,1fr) !important;
    
        }
}

/* Tablet */
@media(min-width: 750px) and (max-width: 1200px){

   .grid2{
       justify-items: center;
   }
   .grid3-footer{
       grid-template-columns: repeat(2,1fr);
   }
}
:root {
  --borderRadius: 10px;
  --transition: ease 0.6s;

  --boxShadow: 0px 0px 7px 0px #d9d9d9;
}
*,
*:before,
*:after {
  box-sizing: content-box;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--colorPrincipal);
  border-radius: 10px;
  border-radius: var(--borderRadius);
}
h1,
h2,
h3,
h4,
h5 {
  font-family: Raleway;
  font-weight: 400;
  margin: 0px;
}
body {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  width: 100%;
  font-weight: 400;
  text-align: left;
  text-transform: none;
  background-color: var(--backgroundColor);
  margin: 0;
  overflow-x: hidden;
  font-style: normal;
  color: var(--fontColor);
  overflow-wrap: break-word;
}

a {
  color: var(--colorPrincipal);
  text-decoration: none;
}
p {
  line-height: 25px;
}
ul {
  list-style: none;
  padding: 0px;
}

.headerSection {
  font-size: 2.2rem;
  margin: 10px;
}

.loginContainer {
  top: 10%;
  position: absolute;
  width: 50%;
  left: 25%;
}

.appElement {
  background-color: var(--backgroundElement);
}

.actividadSelect {
  padding: 5px;
  cursor: pointer;
}
.actividadSelect:hover {
  background-color: var(--colorPrincipal);
  color: #fff;
}

.center {
  text-align: center;
}

.quizzSelector {
  padding: 10px;
  border-radius: 10px;
  border-radius: var(--borderRadius);
  color: #fff;
  background-color: var(--colorPrincipal);
  margin: 10px;
  box-shadow: 0px 0px 7px 0px #d9d9d9;
  box-shadow: var(--boxShadow);
  cursor: pointer;
  font-weight: bolder;
  text-align: center;
  font-size: 1.5rem;
  transition: all 0.8s;
}

.quizzSelector:hover {
  transform: scale(1.2);
}

.rbq_answer_selected,
.rbq_question_overlap_text {
  background-color: var(--colorPrincipal) !important;
}

.rbq_question_overlap_text {
  z-index: -1 !important;
}
.rbq_individual_answer_outer_container,
.rbq_question_overlap_text {
  border-radius: 10px;
  border-radius: var(--borderRadius);
}
.rbq_question_inner_container {
  background-color: transparent !important;
}

.rbq_result_footer {
  display: none !important;
}

.rbq_result_outer_container {
  border-radius: 10px;
  border-radius: var(--borderRadius);
  box-shadow: 0px 0px 20px 0px #323232;
  background-image: linear-gradient(
    180deg,
    #638f66,
    #6caf70 51.44%,
    #6aaa6e
  ) !important;
}




.wpButton {
  position: fixed;
  right: 30px;
  bottom: 35px;
  font-size: 32px;
  border-radius: 30px;
  background: #53bd6a;
  color: #fff;
  width: 48px;
  height: 48px;
  padding: 5px !important;
  line-height: 1;
  box-shadow: 0px 0px 7px 0px #d9d9d9;
  box-shadow: var(--boxShadow);
}
.wpButton svg{
fill:#fff !important
}

.topBarMenu,
.topNavUser {
  display: flex;
  justify-content: center;
  align-content: flex-end;
  align-items: center;
}
.homeListaItinerariosContainer {
  margin-top: 70px;
}

.listadoItinerarioHome {
  padding: 15px;
  border-radius: 10px;
  border-radius: var(--borderRadius);
  transition: all ease 0.6s;
  transition: all var(--transition);
  margin: 5%;
  box-shadow: 0px 0px 7px 0px #d9d9d9;
  box-shadow: var(--boxShadow);
  text-align: center;
  min-height: 198px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;

  color: #fff;
}

.listadoItinerarioHomeTitulo {
  margin-bottom: 35px;
}

.listadoItinerarioHome:hover {
  transform: scale(1.1) rotate(-3deg);
}

.listadoItinerarioHome:nth-child(2n + 1) {
  background-color: var(--success);
}

.listadoItinerarioHome:nth-child(2n + 2) {
  background-color: var(--info);
}





/* botones del editor html */



/* .rdw-block-wrapper,
.rdw-fontfamily-dropdown,
.rdw-fontsize-wrapper,
div[title="Color Picker"],
div[title="Center"],
div[title="Embedded"],
div[title="Emoji"],
div[title="Image"],
div[title="Indent"],
div[title="Justify"],
div[title="Left"],
div[title="Monospace"],
div[title="Ordered"],
div[title="Outdent"],
div[title="Remove"],
div[title="Right"],
div[title="Strikethrough"],
div[title="Subscript"],
div[title="Superscript"],
div[title="Link"]  {
  display: none !important;
}
.editorPaginaContainer .rdw-block-wrapper,
.editorPaginaContainer .rdw-fontfamily-dropdown,
.editorPaginaContainer .rdw-fontsize-wrapper,
.editorPaginaContainer div[title="Color Picker"],
.editorPaginaContainer div[title="Center"],
.editorPaginaContainer div[title="Embedded"],
.editorPaginaContainer div[title="Emoji"],
.editorPaginaContainer div[title="Image"],
.editorPaginaContainer div[title="Indent"],
.editorPaginaContainer div[title="Justify"],
.editorPaginaContainer div[title="Left"],
.editorPaginaContainer div[title="Monospace"],
.editorPaginaContainer div[title="Ordered"],
.editorPaginaContainer div[title="Outdent"],
.editorPaginaContainer div[title="Remove"],
.editorPaginaContainer div[title="Right"],
.editorPaginaContainer div[title="Strikethrough"],
.editorPaginaContainer div[title="Subscript"],
.editorPaginaContainer div[title="Superscript"],
.editorPaginaContainer div[title="Underline"],
.editorPaginaContainer div[title="Unordered"] {
  display: inline-block !important;
}
 */
@media (max-width: 500px) {
  .loginContainer {
    width: 95%;
    left: 2.5%;
  }
}



.topBar {
    position: fixed;
    width: 100%;
    padding: 10px;
    top: 0px;
    background-color: var(--colorPrincipal);
    right: 0px;
    color: #fff;
    display: grid;
    grid-template-columns: 3fr 1fr;
    z-index: 1;
  }
  .topBar span {
    cursor: pointer;
  }
  .topBar span:hover {
    opacity: 0.8;
  }
  .topNavUser {
    text-align: right;
  }
  .brand {
    text-align: center;
    font-weight: bold;
    overflow: hidden;
  }
  
  .brand img {
    height: 54px;
  }


.topBarNav{
    display: flex;
    background: #4d9bd5;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    left: 0px;
    top: 78px;
    box-shadow: var(--boxShadow);
}

.topBarNavElement{
    color:#fff;
    padding: 15px;
    transition: all var(--transition);

}

.topBarNavElement:hover,
.topNavActive > .topBarNavElement{
    background-color: var(--colorPrincipal);
}
#root {
  margin-top: 100px;
  padding: 15px;
}

#map {
  width: 500px;
  height: 400px;
  border-radius: var(--borderRadius);
  box-shadow: 1px 1px 20px 0px #767676;
}

table{
    width: 100%;
}
tr:nth-child(even){
    background-color: var(--backgroundColor);
}
thead{
    border-bottom: 1px solid var(--backgroundColor);
}
td{
    border: 0px;
    padding: 20px;
}
tr{
    cursor: pointer;
}
th{
    padding: 20px;
}

td button{
    margin-left: 3px !important;
}

.onlyPrint{
    display: none;
}

.onlyPrint.onlyPrintShow{
    display: table-cell;
}

/* Responsive */
/* Móvil */
@media(max-width: 500px){
    .appElement > table{
        padding: 10px;
        display: inline-table;
    }
    table{
        display: block;
        overflow-x: scroll;
    }
    tr{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .appElement > table tr{
        justify-content: space-evenly;
    }
    th,td{
        padding: 2%;
    }
    .inputWidget{
        height: 22px !important;
        padding: 2%;
    }
}

.roundSpinner {
    border: 10px solid rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-left-color: var(--colorPrincipal);
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
