#root {
  margin-top: 100px;
  padding: 15px;
}

#map {
  width: 500px;
  height: 400px;
  border-radius: var(--borderRadius);
  box-shadow: 1px 1px 20px 0px #767676;
}
