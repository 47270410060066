input,
textarea,
select,
.wrap > div {
  font-size: 1.1rem;
  width: 100%;
  font-family: inherit;
}
input[type="checkbox"] {
  width: auto;
  margin: 10px;
  transform: scale(1.5);
}

.inputWithIcon {
  display: flex;
  align-content: center;
  width: 100%;
  font-size: 1.2em;
  border: 1px solid var(--backgroundColor);
  margin-top: 10px;
}
.inputWithIcon i {
  color: #a3a2a2;
  font-size: 0.8em;
}
.inputWithIcon > input,
.inputWithIcon > select {
  border: none !important;
  box-shadow: none !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding: 8px;
  font-size: 0.9em;
  
}
.inputWithIcon input::placeholder {
  color: #bebbbb !important;
}
.inputWithIcon > label {
  padding: 8px 0px;
  padding-left: 10px;
  margin-right: 5px;
  margin-top: -4px;
}
.inputSection label {
  color: var(--colorLetterForms);
  font-weight: bolder;
  font-size: 1.1em;
}

.resultadoHead label{
  margin-bottom: 20px;

}
.no {
  padding: 0;
  margin: 0;
}

.appElement textarea {
  padding: 10px;
  margin-left: 25px;
  height: 100%;
  width: 50%;
  max-width: 70%;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.inputSection {
  padding: 5px;
  display: flex;
  margin: 10px;
  width: 94%;
}
.inputSection.politica {
  margin-top: 22px;
}
.inputSection.politica input[type="checkbox"] {
  margin-top: 4px;
  margin-right: 10px;
}
.conditions {
  padding: 5px;
  width: fit-content;
}

input[type="range"] {
  -webkit-appearance: none;
  background-color: var(--colorPrincipal);
  padding: 0px;
  height: 5px;
  border-radius: 5px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid var(--colorPrincipal);
  background-color: var(--backgroundColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
}
.appElement button {
  margin: 15px;
}

.uploadAvatar {
  background-color: var(--headerBackGroundForm);
  border: 2px dashed var(--colorBorderAvatar);
  width: 25%;
  min-width: fit-content;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 39px;
  border-radius: 2px;
}

.uploadAvatar p {
  padding: 20px 34.8771px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
}

label {
  color: var(--colorLetterForms);
  font-weight: 600;
  font-size: 15px;
  padding-top: 1px;
}

input,
textarea,
select {
  color: var(--fontColorForm);
  font-weight: 400;
  border: 2px solid var(--backgroundColor);
  margin-bottom: 15px;
  margin-top: 9px;
  box-shadow: 0px 0px 2px 0px #cdcdcd;
  transition: all var(--transition);
  padding: 10px 12px;
  border-radius: var(--borderRadius);
}
input:focus,
textarea:focus,
select:focus{
  box-shadow: 0px 0px 2px 0px var(--colorPrincipal);

}

.conditions {
  background-color: var(--backGroundColorConditions);
  color: var(--colorConditions);
  padding: 22px 0px;
  padding-left: 25px;
  padding-right: 50px;
  margin: 5px 5px 20px 5px;
  font-weight: 450;
  font-size: 15px;
}

.form-container {
  padding: 15px;
}

.form-container label {
  display: flex;
}
.form-container label {
  margin-top: 20px;
}
.input-15pd {
  width: calc(100% - 25px * 2) !important;
}

.switch {
  position: relative;
  display: block;
  width: 60px;
  height: 13px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.input-switch.style-switch-one input[type="checkbox"]:checked + label:before {
  background-color: var(--colorPrincipal) !important;
  height: 30px !important;
  width: 50px !important;
}

.input-switch.style-switch-one input[type="checkbox"] + label:after {
  width: 30px !important;
  height: 30px !important;
}

.input-switch.style-switch-one input[type="checkbox"] + label {
  background-color: #c5c3c3;
  width: 50px !important;
  height: 30px !important;
}
.input-switch.style-switch-one input[type="checkbox"]:checked + label {
  background-color: var(--colorPrincipal) !important;
}

.wrongInput {
  border-color: var(--danger) !important;
  box-shadow: 0px 0px 3px 1px var(--danger) !important;

}

.editorQwerty {
  height: 275px !important;
  height: 275px !important;
  border: 2px solid var(--backgroundColor);
  padding: 15px;
}

select[multiple] option:checked {
  background: var(--colorPrincipal)
    linear-gradient(0deg, var(--colorPrincipal) 0%, var(--colorPrincipal) 100%);
}

/* widgetForm */

.inputWidget {
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 10%);
  font-size: 16px;
  font-weight: 600;
  height: 35px !important;
  padding: 10px 16px;
  line-height: 30px;
  margin: 0 0 15px 0;
  position: relative;
  background-color: #fff;
  text-align: left;
  color: var(--fontColorForm);
  transition: color 0.3s !important;
}

/* Móvil */
@media (max-width: 500px) {
  .inputSection {
    width: 89%;
  }
}

/* select multiple */
.css-g1d714-ValueContainer {
  max-height: 43px;
  overflow: auto !important;
}
.css-1pahdxg-control:hover {
  border-color: var(--colorPrincipal) !important;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: var(--colorPrincipal) !important;
}

.css-xb97g8:hover {
  background-color: var(--colorPrincipal) !important;
  color: #fff !important;
}
