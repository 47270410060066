#modal-container {
    position: absolute;
    top: 10px;
    left: 0;
    overflow: 100;
    text-align: left;
    width: 100%;
    min-height: 100px;
    max-height: max-content;
    display: none;
  
  }
  #modal{
    opacity: 0;
    display: none;
    margin: 0 auto;
    border-radius: 5px;
    margin-top: 100px;
    z-index: 9;
    position:initial;
    min-width: 40%;
    max-width: 95%;
    max-height: 90%;
    background-color: var(--backgroundElement);
    overflow: hidden auto;
    transition:opacity var(--transition)
  }
  
  #modal p {
    font-size: 16px;
    margin: 0 0 17px;
  }
  
  .showModal{
    display: block !important;
    opacity:1 !important;
    animation-name: showModal;
    animation-duration: var(--transition);
    position: relative;
    top:50%;
  }
  #bgModal{
    z-index: 8;
    position: fixed;
    width:100%;
    height: 100%;
    background-color: black;
    display: none;
    opacity: 0;
    transition: opacity var(--transition);
    top: 0;
  }
  .headerModal{
    padding: 35px;
    background-color: var(--backgroundColor);
    font-size: 24px;
    color: var(--colorTitle);
  }
  
  #contentModal{
    padding: 20px;
  }
  #contentModal .grid1,
  #contentModal .grid2,
  #contentModal .grid3,
  #contentModal .grid4{
    grid-gap: 5px;
  }

  #closeModal{
    margin: 20px;
    float: right;
    background-color: var(--backgroundMenu);
    color: var(--fontColor);
    transition: var(--transition);
    font-size: 25px;
    padding: 5px 10px 2px;
    border-radius: 100%;
  }
  
  
  #closeModal:hover{
    background-color: var(--colorPrincipal);
    color:var(--colorPrincipalHover);
  }
  /* Tablet */
  @media(min-width: 750px) and (max-width: 1200px){
      #modal{
          min-width: 76%;
      }
  }